export default [
  {
    key: 'asesor',
    program: 'Seleksi Kandidat <strong>Asesor</strong>',
    subheading: 'Seleksi Kandidat (Asesor)',
    submenu: false,
    menu: [
      {
        icon: 'mdi-file-account',
        title: 'Verval Ajuan Calon Asesor',
        subtitle: '',
        desc: 'Evaluasi dan Verifikasi Biodata Diri (CV) Calon Asesor',
        color: 'success',
        deepColor: '',
        akses: 'asesor-berkas.index',
        link: 'verval-cv-asesor',
        to: { name: 'verval-cv-asesor' },
      },
      {
        icon: 'mdi-clipboard-check-outline',
        title: 'Verval Profil Kesiapan LPTK',
        subtitle: 'Penjaminan Mutu',
        desc: 'Verval Instrumen Penjaminan Mutu PPG Prajab 2022',
        color: 'success',
        deepColor: 'darken-1',
        akses: 'mutu-verval-kesiapan-lptk.index',
        link: 'mutu-verval',
        to: { name: 'mutu-verval' },
      },
      {
        icon: 'mdi-clipboard-check-outline',
        title: 'Profil Kesiapan LPTK non Penyelenggara',
        subtitle: 'Penjaminan Mutu',
        desc: 'Verval Profil Kesiapan LPTK Non Penyelenggara',
        color: 'orange',
        deepColor: 'darken-1',
        akses: 'mutu-verval-kesiapan-lptk-non.index',
        link: 'mutu-verval-non',
        to: { name: 'mutu-verval-non' },
      },
    ],
  },
  {
    key: 'peserta',
    program: 'Seleksi Kandidat <strong>Peserta</strong>',
    subheading: 'Seleksi Kandidat (Peserta)',
    submenu: false,
    menu: [
      {
        icon: 'mdi-file-account',
        title: 'Daftar Ajuan',
        subtitle: '',
        desc: 'Evaluasi dan Verifikasi Biodata Diri (CV) Calon Peserta',
        color: 'info',
        deepColor: '',
        akses: 'peserta-berkas.index',
        link: 'verval-cv-peserta',
        to: { name: 'verval-cv-peserta' },
      },
      {
        icon: 'mdi-file-account',
        title: 'Verval Kesetaraan',
        subtitle: '',
        desc: 'Evaluasi dan Verifikasi Kesetaraan Calon Peserta',
        color: 'red',
        deepColor: 'darken-4',
        akses: 'peserta-kesetaraan.index',
        link: 'verval-kesetaraan',
        to: { name: 'verval-kesetaraan' },
      },
      {
        icon: 'mdi-file-account',
        title: 'Verval Linieritas',
        subtitle: '',
        desc: 'Evaluasi dan Verifikasi Linieritas Calon Peserta',
        color: 'success',
        deepColor: 'darken-2',
        akses: 'peserta-linieritas.index',
        link: 'verval-linieritas',
        to: { name: 'verval-linieritas' },
      },
      {
        icon: 'mdi-cash',
        title: 'Laporan Pembayaran',
        subtitle: '',
        desc: 'Aksi Pembayaran untuk melanjutkan ke tahap seleksi berikutnya',
        color: 'indigo',
        deepColor: 'darken-2',
        akses: 'peserta-bayar.index',
        link: 'bayar-peserta',
        to: { name: 'bayar-peserta' },
      },
      {
        icon: 'mdi-cash-multiple',
        title: 'Ajuan Pengembalian Dana Pembayaran (Refund)',
        subtitle: '',
        desc: 'Fitur Ajuan Pengembalian Dana Pembayaran (Refund) Seleksi PPG Prajabatan',
        color: 'purple',
        deepColor: '',
        akses: 'peserta-refund.index',
        link: 'refund-peserta',
        to: { name: 'refund-peserta' },
      },
      {
        icon: 'mdi-clipboard-check-outline',
        title: 'Wawancara',
        subtitle: '',
        desc: 'Seleksi Tahap 3 (Wawancara) kandidat peserta PPG Prajabatan',
        color: 'orange',
        deepColor: 'darken-1',
        akses: 'peserta-wawancara.index',
        link: 'wawancara',
        to: { name: 'wawancara' },
      },
      {
        icon: 'mdi-account-check',
        title: 'Kesediaan Peserta',
        subtitle: '',
        desc: 'Daftar Kesediaan Peserta PPG Prajabatan',
        color: 'cyan',
        deepColor: 'darken-1',
        akses: 'peserta-kesediaan.index',
        link: 'peserta-kesediaan',
        to: { name: 'peserta-kesediaan' },
      },
      {
        icon: 'mdi-account-group',
        title: 'Daftar Mahasiswa',
        subtitle: '',
        desc: 'Daftar Mahasiswa PPG Prajabatan',
        color: 'teal',
        deepColor: '',
        akses: 'diklat-mahasiswa.index',
        link: 'mahasiswa',
        to: { name: 'mahasiswa' },
      },
      {
        icon: 'mdi-file-multiple',
        title: 'Berkas PKS',
        subtitle: '(Perjanjian Kerjasama)',
        desc: 'Berisi Berkas PKS (Perjanjian Kerjasama) Mahasiswa',
        color: 'yellow',
        deepColor: 'darken-2',
        akses: 'peserta-pks.index',
        link: 'pks',
        to: { name: 'pks' },
      },
      {
        icon: 'mdi-file-alert',
        title: 'Kendala ePakta',
        subtitle: '',
        desc: 'Berisi Daftar Kendala ePakta Mahasiswa Tahun 2023',
        color: 'red',
        deepColor: 'darken-2',
        akses: 'peserta-privy-kendala.index',
        link: 'kendala-epakta',
        to: { name: 'kendala-epakta' },
      },
      {
        icon: 'mdi-open-in-new',
        title: 'Kelas Diklat',
        desc: 'Tautan Menuju SIM eLearning',
        color: 'cyan',
        deepColor: 'lighten-1',
        akses: '#LMS_URL#',
        link: 'url-lms',
        href: '#LMS_URL#',
      },
      {
        icon: 'mdi-open-in-new',
        title: 'Kelas Diklat 2023',
        desc: 'Tautan Menuju SIM eLearning 2023',
        color: 'pink',
        deepColor: 'lighten-1',
        akses: '#LMS_PMM_URL#',
        link: 'url-pmm-lms',
        href: '#LMS_PMM_URL#',
      },
      {
        icon: 'mdi-target-account',
        title: 'Lokasi Penempatan PPL',
        subtitle: '',
        desc: 'Daftar Mahasiswa PPG Prajabatan beserta Lokasi PPL',
        color: 'orange',
        deepColor: '',
        akses: 'diklat-mahasiswa-ppl.index',
        link: 'mahasiswa-ppl',
        to: { name: 'mahasiswa-ppl' },
      },
      {
        icon: 'mdi-file-document',
        title: 'Dokumen UKMPPG',
        subtitle: '',
        desc: 'Modul Dokumen UKMPPG ini untuk mengisikan tautan video KBM, Laporan Studi Kasus dan Berkas Pendukung lainnya.',
        color: 'green',
        deepColor: 'lighten-1',
        akses: 'asesor-portofolio.index',
        link: 'portofolio-asesor',
        to: { name: 'portofolio-asesor' },
      },
      {
        icon: 'mdi-clipboard-check-outline',
        title: 'Wawancara UKMPPG',
        subtitle: '',
        desc: 'Seleksi Wawancara UKMPPG kandidat peserta PPG Prajabatan',
        color: 'blue',
        deepColor: 'darken-1',
        akses: 'ukppg-wawancara.index',
        link: 'wawancara-ukppg',
        to: { name: 'wawancara-ukppg' },
      },
      {
        icon: 'mdi-account-multiple-plus',
        title: 'Atur Penilai Portofolio',
        subtitle: '',
        desc: 'Modul Portofolio ini untuk mengisikan tautan video KBM, Laporan Studi Kasus dan Berkas Pendukung lainnya.',
        color: 'blue',
        deepColor: '',
        akses: false,
        link: 'portofolio',
        to: { name: 'portofolio' },
      },
      {
        icon: 'mdi-file-document-outline',
        title: 'Status Penilaian UKMPPG',
        subtitle: '',
        desc: 'Modul Portofolio ini untuk mengisikan tautan video KBM, Laporan Studi Kasus dan Berkas Pendukung lainnya.',
        color: 'orange',
        deepColor: '',
        akses: 'peserta-portofolio.index',
        link: 'portofolio-status',
        to: { name: 'portofolio-status' },
      },
      {
        icon: 'mdi-account-multiple-plus',
        title: 'Kelola Penilai UKMPPG',
        subtitle: '',
        desc: 'Modul Kelola Penilai UKMPPG ini untuk meengelola pasangan penilai UKMPPG.',
        color: 'blue',
        deepColor: 'lighten-2',
        akses: 'ukppg-penilai.index',
        link: 'penilai-ukppg',
        to: { name: 'penilai-ukppg' },
      },
      {
        icon: 'mdi-file-check',
        title: 'Data Sertifikat Pendidik',
        subtitle: '',
        desc: 'Daftar Konfirmasi Data Sertifikat Pendidik Bagi Mahasiswa Lulus UKMPPG',
        color: 'blue',
        deepColor: 'lighten-2',
        akses: 'diklat-serdik.index',
        link: 'serdik',
        to: { name: 'serdik' },
      },
      {
        icon: 'mdi-account-tie-voice-outline',
        title: 'Asesor Wawancara',
        subtitle: '',
        desc: 'Daftar Asessor yang berugas pada modul wawancara',
        color: 'orange',
        deepColor: 'lighten-2',
        akses: 'wawancara-asesor.index',
        link: 'asesor-wawancara',
        to: { name: 'asesor-wawancara' },
      },
    ],
  },
  {
    key: 'mutu',
    program: 'Penjaminan Mutu',
    subheading: 'Penjaminan Mutu',
    submenu: false,
    menu: [
      {
        icon: 'mdi-clipboard-check-outline',
        title: 'Profil LPTK Penyelenggara',
        subtitle: 'Penjaminan Mutu',
        desc: 'Instrumen Penjaminan Mutu PPG Prajab',
        color: 'success',
        deepColor: 'darken-1',
        akses: 'mutu-kesiapan-lptk.index',
      },
      {
        icon: 'mdi-clipboard-check-outline',
        title: 'Profil Kesiapan LPTK non Penyelenggara',
        subtitle: 'Penjaminan Mutu',
        desc: 'Profil Kesiapan LPTK Non Penyelenggara',
        color: 'orange',
        deepColor: 'darken-1',
        akses: 'mutu-kesiapan-lptk-non.index',
        link: 'mutu-profil-non',
        to: { name: 'mutu-profil-non' },
      },
      {
        key: 'mutu',
        icon: 'mdi-frequently-asked-questions',
        title: 'Penjaminan Mutu PPG',
        desc: 'Link menuju Program Penjaminan Mutu PPG',
        color: 'teal',
        deepColor: 'darken-2',
        akses: false,
        href: '#MUTU_URL#',
      },
    ],
  },

  {
    key: 'kelola',
    program: 'Kelola Akun',
    subheading: 'Kelola Akun',
    submenu: false,
    menu: [
      {
        icon: 'mdi-account',
        title: 'Kelola Admin',
        desc: 'Pengelolaan data Akun Admin',
        color: 'red',
        deepColor: 'darken-4',
        akses: 'admin.index',
        link: 'admin',
        to: { name: 'admin' },
      },
      {
        icon: 'mdi-school',
        title: 'Kelola Dosen',
        desc: 'Pengelolaan data Dosen',
        color: 'green',
        deepColor: 'lighten-1',
        akses: 'pengampu-dosen.index',
        link: 'pengampu-dosen',
        to: { name: 'pengampu-dosen' },
      },
      {
        icon: 'mdi-school',
        title: 'Kelola Guru Pamong',
        desc: 'Pengelolaan data Guru Pamong',
        color: 'orange',
        deepColor: 'accent-2',
        akses: 'pengampu-pamong.index',
        link: 'pengampu-pamong',
        to: { name: 'pengampu-pamong' },
      },
      {
        icon: 'mdi-school',
        title: 'Kelola Instruktur',
        desc: 'Pengelolaan data Instruktur',
        color: 'purple',
        deepColor: 'accent-2',
        akses: 'pengampu-instruktur.index',
        link: 'pengampu-instruktur',
        to: { name: 'pengampu-instruktur' },
      },
      {
        icon: 'mdi-clipboard-text',
        title: 'Kelola Mata Kuliah',
        desc: 'Pengelolaan data Mata Kuliah',
        color: 'brown',
        deepColor: 'accent-2',
        akses: 'diklat-lptk-matkul.index',
        link: 'diklat-matkul',
        to: { name: 'diklat-matkul' },
      },
    ],
  },
  {
    icon: 'mdi-webhook',
    title: 'ke Aplikasi SIMPKB',
    desc: '',
    color: 'secondary',
    deepColor: 'darken-2',
    sidebar: true,
    akses: true,
    link: 'app-sim-pkb',
    href: '#SIM_PKB_URL#',
  },
];
