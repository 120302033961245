<template>
  <v-main id="layout" class="fill-height main">
    <div class="ribbon ribbon-top-left" v-if="env !== 'production'"><span>DEMO</span></div>
    <router-view :key="$route.fullPath" />
  </v-main>
</template>
<script>
export default {
  name: 'Full',
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
  },
};
</script>
<style scoped>
.main {
  font-family: 'Inter', sans-serif;
  /* background-color: white; */
  background-image: url('https://cdn.siap.id/s3/UI-UX/doc-project-dxs/img/bg-ppg1.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
  background-attachment: fixed;
  height: 100vh;
  background-color: #e4e6fb;
}

.ribbon {
  width: 150px;
  height: 150px;
  overflow: hidden;
  position: fixed;
  z-index: 100000;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #cc0000;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 15px 0;
  background-color: #cc0000;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #fff;
  font: 700 18px/1 'Lato', sans-serif;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.2);
  text-transform: uppercase;
  text-align: center;
}

/* top left*/
.ribbon-top-left {
  top: -10px;
  left: -10px;
}
.ribbon-top-left::before,
.ribbon-top-left::after {
  border-top-color: transparent;
  border-left-color: transparent;
}
.ribbon-top-left::before {
  top: 0;
  right: 0;
}
.ribbon-top-left::after {
  bottom: 0;
  left: 0;
}
.ribbon-top-left span {
  right: -25px;
  top: 30px;
  transform: rotate(-45deg);
}
</style>
