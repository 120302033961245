'use strict';
import Vue from 'vue';
import axios from 'axios';
import store from '@store';
import { dateToString, localDate } from '@utils/format';

let config = {
  // baseURL: process.env.baseURL || process.env.apiUrl || ""
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
  },
  withCredentials: true,
};

const _axios = axios.create(config);
let ajaxRequestCall = 0;

// before a request is made start the nprogress
_axios.interceptors.request.use(
  (config) => {
    ajaxRequestCall++;
    if (store.state && store.state.autosave) {
      store.commit('SET_LOADING', false);
    } else {
      store.commit('SET_LOADING', true);
    }
    return config;
  },
  (error) => {
    ajaxRequestCall = 0;
    store.commit('SET_LOADING', false);
    return Promise.reject(error);
  }
);

// Add a response interceptor
_axios.interceptors.response.use(
  function (response) {
    ajaxRequestCall--;
    // hide loader jika semua request selesai
    if (ajaxRequestCall === 0) {
      store.commit('SET_LOADING', false);
    }
    // Do something with response data
    return response;
  },
  function (error) {
    ajaxRequestCall = 0;
    store.commit('SET_LOADING', false);

    // cek status error
    const { status = 500 } = error.response ? error.response : {};
    let errorMessage = `Maaf!, Ada kesalahan pada data yang membuat aplikasi tidak berjalan.<br/>Silakan memuat ulang halaman`;
    let conectionLost = `Terjadi kegagalan koneksi, pastikan Anda terhubung internet.<br/>Silakan mencoba kembali.`;
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      (status === 500 ? errorMessage : conectionLost);

    const date =
      (error.response.headers && error.response.headers.date && dateToString(new Date(error.response.headers.date))) ||
      dateToString(new Date());
    const akun = store.getters['auth/userLogin'];
    const statusForNotify = [400, 401, 403, 404, 405, 422, 500, 501, 502, 503, 504, 520, 521];
    if (statusForNotify.includes(status)) {
      if (status === 403 && error.response && error.response.data && error.response.data.redirect) {
        window.location.href = error.response.data && error.response.data.redirect;
        return Promise.reject(error.response.data);
      }

      if (status !== 401) {
        Vue.prototype.$error(
          `<span style="font-size: 11px">${akun && akun.email ? `${(akun && akun.email) || '-'} ` : ''} ${localDate(
            date,
            true,
            true,
            false,
            true
          )}
        </span><br/><b>${message || (status === 500 ? errorMessage : conectionLost)}</b>`
        );
      } else {
        if (store.getters['auth/loggedIn']) {
          Vue.prototype.$error('Mohon maaf, sesi Anda telah habis. Silakan melakukan relogin!', [
            {
              label: 'Login',
              event: () => {
                // 401 redirect ke auth login
                store.dispatch('auth/logout').then(() => {
                  window.location.href = process.env.VUE_APP_API_URL + `/auth/logout`;
                });
              },
            },
          ]);
          return;
        }
      }
    }

    // Do something with response error
    return Promise.reject(message || (status === 500 ? errorMessage : conectionLost));
  }
);

export default _axios;
