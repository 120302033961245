import { mapActions } from 'vuex';
import BasePhotoProfil from '@/components/base/BasePhotoProfil';

export default {
  name: 'mixinAction',
  components: {
    BasePhotoProfil,
  },
  methods: {
    ...mapActions('preferensi', ['getPreferensi']),
    ...mapActions('profil', ['uploadFoto', 'ajuan', 'batalAjuan', 'mulaiTbs', 'actions']),
    ...mapActions('cv', ['mulaiCv']),
    ...mapActions('esai', ['mulaiEsai']),
    ...mapActions('auth', ['logout']),

    onLogout() {
      this.$store.commit('SET_LOADING', true);
      this.logout().then(() => {
        window.location.href = process.env.VUE_APP_API_URL + `/auth/logout`;
      });
    },

    onAction({ action, title }) {
      let formData = new FormData();

      switch (action) {
        case 'download':
          window.open(`https://cdn.siap.id/s3/simpkb/Prajab/Asesor/Template%20dokumen/Template%20Dokumen%20Asesor.rar`);
          break;
        case 'download-peserta':
          window.open(`https://files1.simpkb.id/berkas/ppg-prajab/Template_unggahan_Peserta_PPG_Prajab_2022.zip`);
          break;
        case 'download-cv':
          window.open(`${process.env.VUE_APP_API_URL}/gtk/profil/${this.profilID}/biodata`);
          break;
        case 'cv':
        case 'esai':
        case 'tbs':
        case 'tbs-demo':
          if (action === this.$route.name) return;
          if (['cv', 'esai'].includes(action) && this.tutupPendaftaran) this.$router.push({ name: action });
          else
            this.$popupGoto(
              action,
              `Informasi Pengerjaan ${title}`,
              `${action}-masuk`,
              this.statusLengkap && this.statusLengkap[`is_mulai_${action}`]
            );
          break;
        case 'cv-masuk':
        case 'esai-masuk':
          if (!(this.statusLengkap && this.statusLengkap[`is_mulai_${action.split('-')[0]}`])) {
            this[`mulai${this.$titleCase(action.split('-')[0])}`](this.profilID).then(() => {
              this.onReload();
              this.$router.push({ name: action.split('-')[0] });
            });
            return;
          }
          this.$router.push({ name: action.split('-')[0] });
          break;
        case 'tbs-masuk':
          this.mulaiTbs({ id: this.profilID, tbsId: this.configTbs.psp_profil_tbs_id }).then(() => {
            window.location.href = this.configTbs.url_tbs;
          });
          break;
        case 'tbs-demo-masuk':
          this.mulaiTbs({ id: this.profilID, tbsId: this.configTbsDemo.psp_profil_tbs_id }).then(() => {
            window.location.href = this.configTbsDemo.url_tbs;
          });
          break;
        case 'microteaching':
          this.$router.push({ name: this.isKasek ? 'microteaching-kasek' : 'microteaching-instruktur' });
          break;
        case 'iht':
          this.$router.push({ name: 'iht' });
          break;
        case 'diklat-iht':
          this.$router.push({ name: 'diklat-iht' });
          break;
        case 'upload-sehat':
          if (this.mVervalSehat.ajuan && !this.mVervalSehat.revisi) {
            this.$error('Berkas Surat Sehat telah diajukan');
            return;
          }

          this.$set(this, 'formulir', {});
          this.$set(this.formulir, 'title', `Berkas Surat Keterangan Sehat`);
          this.$set(this.formulir, 'format', `harus bertipe JPEG/JPG/PNG/PDF`);
          this.$set(this.formulir, 'rules', { format: 'JPEG|JPG|PNG|PDF', required: true });
          this.$set(this.formulir, 'action', { action: 'upload-sehat-save', title: 'Surat Keterangan Sehat' });
          this.$set(this.formulirModal, 'component', null);
          this.$set(this.formulirModal, 'title', `Berkas Surat Keterangan Sehat`);
          this.$set(this.formulirModal, 'action', { action: 'upload-sehat-save', title: 'Surat Keterangan Sehat' });
          this.$refs.modal.open();
          this.$nextTick(() => {
            this.$refs.formulir.reset();
          });
          break;
        case 'upload-sehat-save':
          formData.append('label', 'sehat');
          formData.append('file', this.$refs.formulir.form.file);

          this.actions({
            id: this.profilID,
            action: 'berkas-sehat-insert',
            params: formData,
            config: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          })
            .then(() => {
              this.$refs.modal.close();
              this.onReload();
            })
            .catch(() => {
              this.$refs.modal.loading = false;
            });
          break;
        case 'upload-sehat-view':
          window.open(this.berkasSehat.url_berkas, '_blank');
          break;
        case 'upload-mundur':
          if (this.mVervalMundur.ajuan && !this.mVervalMundur.revisi) {
            this.$error('Berkas Surat Tidak mengundurkan diri telah diajukan');
            return;
          }

          this.$set(this, 'formulir', {});
          this.$set(this.formulir, 'title', `Berkas Surat Pernyataan Komitmen`);
          this.$set(this.formulir, 'format', `harus bertipe JPEG/JPG/PNG/PDF`);
          this.$set(this.formulir, 'rules', { format: 'JPEG|JPG|PNG|PDF', required: true });
          this.$set(this.formulir, 'action', {
            action: 'upload-mundur-save',
            title: 'Surat Pernyataan Komitmen',
          });

          this.$set(this.formulirModal, 'component', null);
          this.$set(this.formulirModal, 'title', `Berkas Surat Pernyataan Komitmen`);
          this.$set(this.formulirModal, 'action', { action: 'upload-mundur-save', title: 'Surat Pernyataan Komitmen' });

          this.$refs.modal.open();
          this.$nextTick(() => {
            this.$refs.formulir.reset();
          });
          break;
        case 'upload-mundur-save':
          formData.append('label', 'mundur');
          formData.append('file', this.$refs.formulir.form.file);

          this.actions({
            id: this.profilID,
            action: 'berkas-mundur-insert',
            params: formData,
            config: {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            },
          })
            .then(() => {
              this.$refs.modal.close();
              this.onReload();
            })
            .catch(() => {
              this.$refs.modal.loading = false;
            });
          break;
        case 'upload-mundur-view':
          window.open(this.berkasMundur.url_berkas, '_blank');
          break;
        case 'daftar-guru':
          this.$router.push({ name: 'diklat-komite-kandidat' });
          break;
        case 'to-link-zoom':
          window.open('https://us02web.zoom.us/j/88217630589?pwd=NnNqNzlIeUdNb1FaQ0dUY3U0OTZiQT09', '_blank');
          break;
        case 'dkp-peserta-save':
          this.onSaveDkp();
          break;
      }
    },

    onReload() {
      window.location.reload();
    },

    onUploadFoto(data) {
      this.uploadFoto(data).then(() => {
        this.getPreferensi(true);
      });
    },

    onAjuan() {
      if (this.tutupAjuan && this.kVerval !== 5) {
        const msg = `Pendaftaran <b>seleksi Tahap ${this.tahap}</b> sebagai
                    <b>${this.isAsesor ? 'Asesor' : 'Peserta'}</b>
                    Program Pendidikan Profesi Guru (PPG) - PRAJAB ${this.config.tahun} telah berakhir`;
        this.$error(msg);
        return;
      }

      this.$confirm(`<h3>Anda yakin ingin mengajukan Verval sekarang?</h3>`, `Ajuan Verval`, {
        tipe: 'success',
      }).then(() => {
        this.ajuan(this.profilID).then(() => {
          this.$success(`Data Anda berhasil diajukan`);
          this.onReload();
        });
      });
    },

    onBatalAjuan() {
      if (this.tutupAjuan) {
        const msg = `Pendaftaran <b>seleksi Tahap ${this.tahap}</b> sebagai
                    <b>${this.isAsesor ? 'Asesor' : 'Peserta'}</b>
                    Program Pendidikan Profesi Guru (PPG) - PRAJAB ${this.config.tahun} telah berakhir`;
        this.$error(msg);
        return;
      }

      this.$confirm(`<h3>Anda yakin ingin membatalkan Ajuan Verval?</h3>`, `Batal Ajuan Verval`, {
        tipe: 'error',
      }).then(() => {
        this.batalAjuan(this.profilID).then(() => {
          this.$success(`Ajuan Anda berhasil dibatalkan`);
          this.onReload();
        });
      });
    },

    onSaveDkp() {
      let rawsData = Object.assign({}, this.$refs.modalForm.form || {});
      const kelases = rawsData['kelas'].map((item) => item.kelas);
      const mapels = rawsData['mapel'].map((item) => item.mapel);

      rawsData['email_belajar'] = Number(rawsData['hasBelajar']) === 1 ? rawsData['email_belajar'] : '';
      if (kelases.length && mapels.length) {
        rawsData['kelas'] = kelases.filter((a) => a);
        rawsData['mapel'] = mapels.filter((a) => a);
      }

      const data = [
        'pengawas',
        'pengawas_nama',
        'pengawas_nuptk',
        'guru_bk',
        'guru_bk_nama',
        'guru_bk_nuptk',
        'guru_bk_simpkb',
      ];

      let params = Object.assign({}, rawsData);
      if (rawsData && rawsData.peran === 'kasek') {
        params['data'] = {};
        for (const key in rawsData) {
          if (data.includes(key)) {
            params['data'][key] = rawsData[key];
            // delete key
            delete params[key];
          }
        }
      }

      this.saveDkp(params)
        .then(() => {
          this.$refs.modal.close();
          this.getPreferensi(true);
        })
        .catch(() => {
          this.$refs.modal.loading = false;
        });
    },
  },
};
