/* eslint-disable no-empty-pattern */
import $http from '@plugins/axios';
import store from '@store';
import { isObject, isJson } from '@/utils/format';

const newState = {
  isLogin: false,
  role: null,
  akun: null,
  ptk: null,
  env: null,
  instansi_id: null,
  pelatih_ahli: null,
};

export const state = newState;

export const mutations = {
  SET_LOGIN(state, value) {
    state.isLogin = value ?? false;
  },
  SET_AKUN(state, akun) {
    state.akun = akun ?? null;
  },
  SET_ENV(state, newValue) {
    state.env = newValue ?? null;
  },
  SET_PTK(state, ptk) {
    state.ptk = ptk ?? null;
  },
  SET_INSTANSI_ID(state, newValue) {
    state.instansi_id = newValue ?? null;
  },
  SET_ROLE(state, role) {
    state.role = role ?? null;
  },
};

export const getters = {
  instansiId(state) {
    return state.instansi_id;
  },

  loggedIn(state) {
    return state.isLogin;
  },

  userLogin(state) {
    return state.role === 'instansi' ? state.akun : state.ptk;
  },
};

export const actions = {
  logout({ commit }) {
    commit('SET_LOGIN', false);
    return Promise.resolve(true);
  },

  cekEmail({}, email) {
    return $http
      .get(`/registrasi-instruktur/email`, {
        params: { email: email },
      })
      .then(({ data }) => data);
  },

  daftar({}, params) {
    return $http.post('registrasi-instruktur/create', params).then(({ data }) => data);
  },

  fetchAktivasi({}, { token, tipe }) {
    return $http.get(tipe === 'peserta' ? '/aktivasi-peserta' : `/aktivasi-asesor`, {
      params: { token: token },
    });
  },

  aktivasiValidate({}, { params, tipe }) {
    return $http
      .post(tipe === 'peserta' ? 'aktivasi-peserta/validasi' : `aktivasi-asesor/validasi`, params)
      .then(({ data }) => data);
  },
  aktivasi({}, { params, tipe }) {
    return $http
      .post(tipe === 'peserta' ? 'aktivasi-peserta/save' : `aktivasi-asesor/save`, params)
      .then(({ data }) => data);
  },

  reaktivasi({}, params) {
    return $http.post(`registrasi-instruktur/resend-email`, params).then(({ data }) => data);
  },

  setInstansiId({ commit }, id) {
    commit('SET_INSTANSI_ID', id);
    return Promise.resolve(true);
  },

  // commit, dispatch, rootState
  async checkUser({ commit, dispatch }) {
    // sudah pernah login
    if (state && state.isLogin) {
      const role = state.role;
      const done = await dispatch('setRole', role);
      return Promise.resolve(done);
    }

    let respAkun;
    try {
      respAkun = await $http.get('akun').then(({ data }) => data);
    } catch (e) {
      respAkun = null;
    }

    // Error Gagal dapat resp
    if (!respAkun) return Promise.reject(new Error('Gagal mendapatkan Akun!'));

    // Error belum login
    if (!(respAkun && respAkun.login)) return Promise.reject(new Error('User belum login!'));

    // User login!
    commit('SET_LOGIN', respAkun?.login);
    commit('SET_AKUN', respAkun?.akun);
    commit('SET_PTK', respAkun?.ptk);
    commit('SET_ENV', respAkun?.env);
    commit('SET_INSTANSI_ID', respAkun?.instansi_id);

    const multiRole = isObject(respAkun?.akun ?? {}) && isObject(respAkun?.ptk ?? {});
    const pathname = window.location.pathname;
    const splitPath = pathname.split('/');
    const isPathInstansi = splitPath && splitPath[1] === 'i';
    const ptk = respAkun?.ptk || {};

    let role = '';
    if (multiRole) {
      role = isPathInstansi ? 'instansi' : getSavedState(`role_${(ptk && ptk.ptk_id) || ''}`) || 'instansi';
    } else {
      role = respAkun?.akun && isObject(respAkun?.akun ?? {}) ? 'instansi' : 'gtk';
    }

    const done = await dispatch('setRole', role);
    return Promise.resolve(done);
  },

  async setRole({ commit }, role) {
    commit('SET_ROLE', role);
    saveState(`role_${(state.ptk && state.ptk.ptk_id) || ''}`, role);
    await regModules(role);
    return Promise.resolve(true);
  },

  async userPreferensi({ dispatch }, id) {
    let preferensi;

    if (id) await dispatch('setInstansiId', id);

    try {
      preferensi = await dispatch('preferensi/getPreferensi', null, {
        root: true,
      });
    } catch (err) {
      return Promise.reject(err);
    }
    return Promise.resolve(preferensi);
  },
};

async function regModules(role) {
  const modules = await import(`./${role}`);

  // hapus module yang teregister agar tidak duplicate
  for (let key in modules.default) {
    if (store && store.state && store.state[key]) {
      store.unregisterModule(key);
    }
  }
  // register module dynamically
  for (let key in modules.default) {
    if (!(store && store.state && store.state[key])) {
      store.registerModule(key, modules.default[key]);
    }
  }
}

// Private helpers
// ===
function getSavedState(key) {
  const json = isJson(window.localStorage.getItem(key)) ? window.localStorage.getItem(key) : '';
  let data;
  try {
    data = JSON.parse(json);
  } catch (e) {
    data = null;
  }
  return data;
}

function saveState(key, state) {
  window.localStorage.setItem(key, state && Object.keys(state).length ? JSON.stringify(state || {}) : state || '');
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
};
